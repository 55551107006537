<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="editar estado">
          <state-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import StateForm from "@/components/Forms/StateForm.vue";
import CCard from "@/components/CCard.vue";
export default {
  components: {
    StateForm,
    CCard,
  },
};
</script>
